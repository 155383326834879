export const environment = {
  production: false,
  batchSdnCalls: process.env['NX_PUBLIC_BATCH_SDN_CALLS'] !== '0',
  appVersion: process.env['NX_PUBLIC_APP_VERSION'],
  appEnv: process.env['NX_PUBLIC_APP_ENV'],
  spinsLlcId: '0013p000029NprlAAC',
  powerTabsSfID: 'a56Dm000000XxtPIAS',
  panelDataSfID: 'a56VE000000EEMTYA4',
  panelDataIframeUrl: 'https://spinsllc.auth0.com/samlp/sT8Wtkhc67OUzaSvrzuHGFtHaS1fXqQH/',
  remoteAdminUrl: 'https://admin-dot-spins-insights-portal-uat.uc.r.appspot.com/',
  remoteRetailInsightsUrl: 'https://uat.retail-platform.spins.team',
  remoteBrandInsightsUrl: 'https://uat.brand-insights.spins.team',
  remotePartnerInsightsUrl: 'https://spins-partner-insights-uat.uc.r.appspot.com/',
  remotePowerTabsUrl: 'https://uat.powertabs.spins.team/',
  spinsDigestUrl: 'https://uat.digest-ui.spins.team',
  onMaintenance: false,
  allowedCompanyTypesDefaultFilters: ['RETAILER'],
};
